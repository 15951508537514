import { h, ref, render, effect } from 'vue';
import Toast from './components/ToastCom.vue';

let timeId;

// 抽离不同平台 实现 具体 api
export function baseToast(opts, { mountEl }) {
  if (!opts) return;

  let defaultOpts = {
    message: '',
    placement: 'top',
    duration: 3000,
  };

  if (typeof opts === 'string') {
    defaultOpts.message = opts;
  }

  if (typeof opts === 'object') {
    defaultOpts = {
      ...defaultOpts,
      ...opts,
    };
  }

  mountEl && mountEl(defaultOpts);
}

//  web 平台
export function toast(opts) {
  return baseToast(opts, {
    mountEl(a) {
      if (timeId) {
        clearTimeout(timeId);
        timeId = undefined;
      }

      const t = ref(true);
      effect(() => {
        render(
          h(Toast, {
            show: t.value,
            message: a.message,
            placement: a.placement,
          }),
          document.body
        );
      });

      timeId = setTimeout(() => {
        t.value = false;
      }, a.duration);
    },
  });
}

