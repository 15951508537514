<script setup>
import { defineProps } from "vue";
const props = defineProps({
  show: {
    type: Boolean,
  },
  message: {
    type: String,
  },
  placement: {
    type: String,
  },
});
</script>

<template>
  <transition name="fade">
    <div v-if="props.show" :class="['toast', placement]">
      {{ props.message }}
    </div>
  </transition>
</template>

<style scoped lang="less">
/* vue动画过渡效果设置 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.toast {
  position: fixed;
  padding: 10px 8px;
  left: 50%;
  background-color: black;
  color: #fff;
  border-radius: 8px;
}

.toast.top {
  top: 50px;
  transform: translateX(-50%);
}
.toast.center {
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

